
import { computed, defineComponent, onMounted, ref, toRaw, unref } from 'vue'
import { useStore } from 'vuex'
import { listCancelationRequest } from '@/composables/user/useCancelationRequest'
import { getProfileImage } from '@/composables/profile/profileImage'
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import { useDateFns } from '@/composables/locale/useDateFns'


export default defineComponent({
  components: { DashboardContent },

  setup () {
    const store = useStore()
    const cancelationsRequest = ref<any>([])

    const { format } = useDateFns()

    const profiles = computed(() => toRaw(store.getters['profiles/all']))

    onMounted(async() => {
      const requests = await listCancelationRequest()
      cancelationsRequest.value = requests.map((request: any) => {
        const profile = unref(profiles).find((profile: any) => profile.id === request.profile)
        return {
          ...request,
          profile: {
            id: profile.id,
            name: profile.name,
            image: getProfileImage(profile),
            category: profile.category || null
          }
        }
      })
    })

    return {
      cancelationsRequest,
      getProfileImage,
      format
    }
  }
})
