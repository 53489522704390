<template>
  <dashboard-content>
    <template v-slot:actions>
      <router-link
        :to="{ name: 'unsubscribe-list' }"
        class="btn btn-primary w-max block md:mb-12"
      >
        {{ $t('page.cancellation_survey.action.cancellation_requests') }}
      </router-link>
    </template>
    <div class="card pt-4" v-if="isLoaded">
      <div class="card-body w-full">
        <div
          class="lg:w-5/6 mx-auto"
          v-if="activeProfiles.length"
        >
          <div v-if="step === 1">
            <div class="flex flex-col md:flex-row md:space-x-12">
              <img
                class="transform rotate-6 w-1/2 sm:w-1/3 md:w-auto mx-auto"
                src="@/assets/img/sad_manyrobot.png"
                alt="manyrobot"
                style="max-width: 230px"
              />
              <div class="flex flex-col justify-start">
                <h2
                  class="mt-0 mb-6 md:my-12 text-center md:text-left"
                  v-html="$t('page.cancellation_survey.sentence.title-html')"
                />

                <p class="mb-12 md:mb-0 text-center md:text-left">
                  {{ $t('page.cancellation_survey.sentence.description') }}
                </p>
              </div>
            </div>

            <h3
              class="text-center my-12"
            >
              {{ $t('page.cancellation_survey.sentence.ask_for_evaluation') }}
            </h3>

            <div class="text-lg font-semibold my-4">
              1- {{ $t('page.cancellation_survey.sentence.which_profile') }}
            </div>
            <ul class="ml-4 my-4">
              <li
                class="flex items-center space-x-2"
                v-for="profile in activeProfiles"
                :key="profile.id"
              >
                <input
                  type="checkbox"
                  :id="profile.id"
                  :value="profile.id"
                  v-model="selectedProfiles"
                />
                <label :for="profile.id">
                  {{ profile.name }}
                  <span class="text-xs ml-2" v-if="profile.categoryName">
                    ({{ profile.categoryName }})
                  </span>
                </label>
              </li>
              <li
                class="flex items-center space-x-2"
                v-if="activeProfiles.length > 1"
              >
                <input
                  type="checkbox"
                  id="selectAll"
                  @change="selectAll"
                />
                <label for="selectAll">{{ $t('page.cancellation_survey.sentence.all_profiles') }}</label>
              </li>
            </ul>

            <div class="flex flex-col space-y-4">
              <div v-for="question in surveyQuestions" :key="question.key">
                <div class="text-lg font-semibold my-4">
                  {{ question.label }}
                </div>
                <survey-options
                  v-if="question.type === 'change'"
                  v-model="question.value"
                  :survey-id="question.key"
                  :options="question.options"
                />
                <textarea
                  class="w-full border border-gray-400 rounded p-2"
                  v-if="question.type === 'text'"
                  v-model="question.value"
                  rows="5"
                  :placeholder="$t('page.cancellation_survey.form.opinion.placeholder')"
                >
                </textarea>
              </div>
            </div>

            <div class="text-center">
              <button
                :disabled="!isValid"
                @click.prevent="submitQuestions()"
                class="btn btn-primary my-4 mx-auto"
              >
                {{ $t('page.cancellation_survey.action.send') }}
              </button>
            </div>
          </div>
          <div v-if="step === 2">

            <div class="flex flex-col md:flex-row md:space-x-12">
              <img
                class="transform rotate-6 w-1/2 sm:w-1/3 md:w-auto mx-auto"
                src="@/assets/img/sad_manyrobot.png"
                alt="manyrobot"
                style="max-width: 230px"
              />
              <div class="flex flex-col justify-start">
                <h3 class="font-semibold mt-0 mb-6 md:my-12">
                  {{ $t('page.cancellation_survey.sentence.we_will_miss_you', { userName }) }}
                </h3>

                <div class="space-y-4">
                  <p class="font-semibold">
                    {{ $t('page.cancellation_survey.sentence.tanks') }}
                  </p>
                  <p
                    v-if="insatisfactions.length"
                    class="font-semibold"
                    v-html="$t('page.cancellation_survey.sentence.insatisfactions_message-html', { insatisfactions: insatisfactions.join(', ') })"
                  />
                  <p
                    v-else
                    v-html="$t('page.cancellation_survey.sentence.satisfactions_message-html', { satisfactions: satisfactions.join(', ') })"
                  />
                </div>
              </div>
            </div>

            <h3 class="text-center my-12">{{ $t('page.cancellation_survey.sentence.see_more') }}</h3>

            <profiles-usage-time-offer :profilesUsageTime="profilesUsageTime" />

            <div class="flex items-center justify-center my-12 space-x-4">
              <button
                @click.prevent="requestCancelation(false)"
                class="btn"
              >
                {{ $t('page.cancellation_survey.action.request_cancellation') }}
              </button>

              <button
                @click.prevent="requestCancelation(true)"
                class="btn btn-primary"
              >
                {{ $t('page.cancellation_survey.action.accept_offer') }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="lg:w-5/6 mx-auto flex flex-col justify-center items-center"
          v-else
        >
          <h3 class="mt-0 mb-6 md:my-12 text-center">
            {{ $t('page.cancellation_survey.sentence.alread_have_cancellation_requests') }}
          </h3>
          <router-link
            :to="{ name: 'unsubscribe-list' }"
            class="btn btn-primary w-max block md:mb-12"
          >
            {{ $t('page.cancellation_survey.action.cancellation_requests') }}
          </router-link>

        </div>
      </div>
    </div>

  </dashboard-content>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, reactive, ref, toRaw, unref } from 'vue'
import { useStore } from 'vuex'
import { differenceInCalendarDays } from 'date-fns'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'
import { firestore } from '@/plugins/firebase'
import { currentUser } from '@/composables/user/currentUser'
import { listCancelationRequest } from '@/composables/user/useCancelationRequest'
import { listSubscriptions } from '@/composables/user/useSubscriptions'
import { getQuestions } from '@/components/cancelation/SurveyQuestions'
import { useScrollTop } from '@/composables/useScrollTop'
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import ProfilesUsageTimeOffer from '@/components/cancelation/profilesUsageTimeOffer.vue'
import SurveyOptions from '@/components/cancelation/SurveyOptions.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    DashboardContent,
    ProfilesUsageTimeOffer,
    SurveyOptions,
  },

  setup () {
    const store = useStore()
    const router = useRouter()

    const { user } = currentUser()

    const isLoaded = ref(false)
    const step = ref(1)
    const subscriptions = ref<any>([])
    const cancelationsRequest = ref<any>([])
    const selectedProfiles = ref<string[]>([])
    const questions = getQuestions()
    const surveyQuestions = reactive(questions)

    const { t } = useI18n()

    onBeforeMount(async() => {
      subscriptions.value = await listSubscriptions()
      cancelationsRequest.value = await listCancelationRequest()
      isLoaded.value = true
    })

    const profiles = computed(() => toRaw(store.getters['profiles/all']))

    const activeProfiles = computed(() => {
      return unref(profiles)
        .map((profile: any) => {
          const profileSubscription = unref(subscriptions)
            .find((subscription: any) => subscription.profile === profile.id)
          const cancelationRequest = unref(cancelationsRequest)
            .find((cancelation: any) => cancelation.profile === profile.id)
          return {
            id: profile.id,
            name: profile.name,
            subscriptionId: profileSubscription ? profileSubscription.id : null,
            cancelationRequestId: cancelationRequest ? cancelationRequest.id : null,
            categoryName: profile.category? profile.category.name : null
          }
        })
        .filter((profile: any) => profile.subscriptionId && !profile.cancelationRequestId)
    })

    const isValid = computed(() => {
      const completedQuestions = unref(surveyQuestions)
        .filter((question: { type: string, value: number | string | null }) => {
          if (question.type === 'change') {
            return question.value && question.value > 0
          } else if (question.type === 'text') {
            return question.value !== ''
          }
        })

      return unref(surveyQuestions).length === completedQuestions.length && unref(selectedProfiles).length
    })

    const userName = computed(() => {
      return unref(user).name?.split(' ')[0]
    })

    const profilesUsageTime = computed(() => {
      return unref(profiles)
        .filter((profile: any) => {
          return unref(selectedProfiles).includes(profile.id)
        })
        .map((profile: any) => {
          const createdAt = new Date(profile.createdAt.seconds * 1000)
          return {
            name: profile.name,
            createdAt,
            usageTimeDays: differenceInCalendarDays(new Date(), createdAt)
          }
        })
    })

    const insatisfactions = computed(() => {
      return unref(surveyQuestions)
        .filter((question: { type: string, resume: string, value: number | string | null }) => {
          return question.type === 'change'
            && question.resume !== ''
            && question.value
            && question.value <= 3
        })
        .map((question: { resume: string }) => question.resume.toLowerCase())
    })

    const satisfactions = computed(() => {
      return unref(surveyQuestions)
        .filter((question: { type: string, resume: string, value: number | string | null }) => {
          return question.type === 'change'
            && question.resume !== ''
            && question.value
            && question.value > 3
        })
        .map((question: { resume: string }) => question.resume.toLowerCase())
    })

    const selectAll = (e: Event & { target: HTMLInputElement }) => {
      const element = e.target
      if (element.checked) {
        selectedProfiles.value = unref(activeProfiles).map((profile: { id: string }) => profile.id)
      } else {
        selectedProfiles.value = []
      }
    }

    const submitQuestions = () => {
      step.value = 2
      useScrollTop('.content')
    }

    const requestCancelation = async (acceptOffer: boolean = false) => {
      try {
        const survey = unref(surveyQuestions)
          .reduce((current: any, profile: any) => {
            return {
              ...current,
              [profile.key]: profile.value
            }
          }, {})

        const solicitations = unref(profiles)
          .filter((profile: any) => {
            return unref(selectedProfiles).includes(profile.id)
          })
          .map((profile: any) => {
            return {
              survey,
              signatureDate: new Date(profile.plan.signatureDate.seconds * 1000),
              profile: profile.id,
              profileName: profile.name,
              subscriptionId: unref(subscriptions).find((subscription: any) => subscription.profile === profile.id).id,
              user: unref(user).id,
              email: unref(user).email,
              requestDate: new Date(),
              status: 'Open',
              acceptOffer,
              offer: acceptOffer ? 'Consultoria' : null
            }
          })

        await Promise.all(
          solicitations.map(async (solicitation: any) => {
            return firestore.collection('cancelRequests').add(solicitation)
          })
        )

        Swal.fire({
          title: t('state.cancellation_survey.title'),
          text: t('state.cancellation_survey.message'),
          confirmButtonText: t('state.cancellation_survey.action_confirm'),
        }).then(result => {
          if (result.value) {
            router.push({ name: 'home' })
          }
        })
      } catch (error) {
        console.log(error)
        Swal.fire({
          title: t('state.cancellation_survey.error_title'),
          text: t('state.cancellation_survey.error_message'),
          confirmButtonText: t('state.cancellation_survey.error_action_confirm'),
        })
      }
    }

    return {
      activeProfiles,
      insatisfactions,
      isLoaded,
      isValid,
      profilesUsageTime,
      requestCancelation,
      satisfactions,
      selectAll,
      selectedProfiles,
      step,
      submitQuestions,
      surveyQuestions,
      user,
      userName,
    }
  }
})
</script>
