<template>
  <dashboard-content>
    <div class="card pt-4">
      <div class="card-body w-full">
        <h3 class="mt-0 mb-6 text-center">
          {{ $t('page.cancellation_requests.sentence.title') }}
        </h3>

        <div
          v-if="cancelationsRequest.length"
        >
          <p>
            {{ $t('page.cancellation_requests.sentence.description') }}
          </p>
          <p class="mt-4">
            {{ $t('page.cancellation_requests.sentence.follow_your_requests') }}
          </p>

          <table class="my-12">
            <thead>
              <tr>
                <th>{{ $t('page.cancellation_requests.table_head.profile') }}</th>
                <th class="text-center">{{ $t('page.cancellation_requests.table_head.date') }}</th>
                <th class="text-center">{{ $t('page.cancellation_requests.table_head.statue') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="request in cancelationsRequest" :key="request.id">
                <td>
                  <div class="flex justify-start items-center flex-nowrap">
                    <img
                      class="flex-none w-8 h-8 rounded-full object-cover shadow-lg mr-2"
                      :src="getProfileImage(request.profile)"
                    >
                    <div>
                      <div class="font-semibold whitespace-nowrap">{{ request.profile.name }}</div>
                      <div class="text-xs text-gray-500">{{ request.profile.category?.name }}</div>
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  {{ request.requestDate ? format(request.requestDate.seconds * 1000) : '' }}
                </td>
                <td>
                  <div class="w-full flex justify-center">
                    <span
                      class="badge-outline text-sm text-green-600"
                      v-if="request.status && request.status === 'Open'"
                    >
                      {{ $t('page.cancellation_requests.sentence.in_progress') }}
                    </span>
                    <span
                      class="badge-outline text-sm text-red-700"
                      v-else
                    >
                      {{ $t('page.cancellation_requests.sentence.finished') }}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="my-12 text-center alert alert-info"
          v-else
        >
          {{ $t('page.cancellation_requests.sentence.no_requests') }}
        </div>

        <p class="my-12">
          {{ $t('sentence.you_need_help') }}
          <a class="text-blue-600" href="https://suporte.manycontent.com/faq/article/como-cancelo-a-minha-assinatura" target="_blank">{{ $t('action.support_team') }}</a>
        </p>
      </div>
    </div>
  </dashboard-content>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, toRaw, unref } from 'vue'
import { useStore } from 'vuex'
import { listCancelationRequest } from '@/composables/user/useCancelationRequest'
import { getProfileImage } from '@/composables/profile/profileImage'
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import { useDateFns } from '@/composables/locale/useDateFns'


export default defineComponent({
  components: { DashboardContent },

  setup () {
    const store = useStore()
    const cancelationsRequest = ref<any>([])

    const { format } = useDateFns()

    const profiles = computed(() => toRaw(store.getters['profiles/all']))

    onMounted(async() => {
      const requests = await listCancelationRequest()
      cancelationsRequest.value = requests.map((request: any) => {
        const profile = unref(profiles).find((profile: any) => profile.id === request.profile)
        return {
          ...request,
          profile: {
            id: profile.id,
            name: profile.name,
            image: getProfileImage(profile),
            category: profile.category || null
          }
        }
      })
    })

    return {
      cancelationsRequest,
      getProfileImage,
      format
    }
  }
})
</script>
