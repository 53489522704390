
import { defineComponent, ref, unref, watch } from 'vue'

export default defineComponent({
  props: {
    surveyId: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => ([])
    }
  },

  emits : [
    'update:modelValue'
  ],
  
  setup (props, { emit }) {
    const selectedOption = ref<number | null>(null)

    watch(selectedOption, () => {
      emit('update:modelValue', unref(selectedOption))
    })

    return {
      selectedOption
    }
  }
})
