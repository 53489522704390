import { useI18n } from "vue-i18n"


export const getQuestions = () => {
  
  const { t } =  useI18n()

  return [
    {
      label: `2- ${t('page.cancellation_survey.form.content_satisfaction.label')}`,
      resume: t('page.cancellation_survey.form.content_satisfaction.resume'),
      value: null,
      key: 'contentSatisfaction',
      type: 'change',
      options: [
        {
          label: t('page.cancellation_survey.form.options.very_satisfied'),
          value: 5
        },
        {
          label: t('page.cancellation_survey.form.options.satisfied'),
          value: 4
        },
        {
          label: t('page.cancellation_survey.form.options.indifferent'),
          value: 3
        },
        {
          label: t('page.cancellation_survey.form.options.dissatisfied'),
          value: 2
        },
        {
          label: t('page.cancellation_survey.form.options.very_unsatisfied'),
          value: 1
        }
      ]
    },
    {
      label: `3- ${t('page.cancellation_survey.form.schedules_and_post_satisfaction.label')}`,
      resume: t('page.cancellation_survey.form.schedules_and_post_satisfaction.resume'),
      value: null,
      key: 'schedulesAndPostSatisfaction',
      type: 'change',
      options: [
        {
          label: t('page.cancellation_survey.form.options.very_satisfied'),
          value: 5
        },
        {
          label: t('page.cancellation_survey.form.options.satisfied'),
          value: 4
        },
        {
          label: t('page.cancellation_survey.form.options.indifferent'),
          value: 3
        },
        {
          label: t('page.cancellation_survey.form.options.dissatisfied'),
          value: 2
        },
        {
          label: t('page.cancellation_survey.form.options.very_unsatisfied'),
          value: 1
        }
      ]
    },
    {
      label: `4- ${t('page.cancellation_survey.form.analytics_relevancy.label')}`,
      resume: t('page.cancellation_survey.form.analytics_relevancy.resume'),
      value: null,
      key: 'relevancy',
      type: 'change',
      options: [
        {
          label: t('page.cancellation_survey.form.options.very_satisfied'),
          value: 5
        },
        {
          label: t('page.cancellation_survey.form.options.satisfied'),
          value: 4
        },
        {
          label: t('page.cancellation_survey.form.options.indifferent'),
          value: 3
        },
        {
          label: t('page.cancellation_survey.form.options.dissatisfied'),
          value: 2
        },
        {
          label: t('page.cancellation_survey.form.options.very_unsatisfied'),
          value: 1
        }
      ]
    },
    {
      label: `5- ${t('page.cancellation_survey.form.support_satisfaction.label')}`,
      resume: t('page.cancellation_survey.form.support_satisfaction.resume'),
      value: null,
      key: 'supportSatisfaction',
      type: 'change',
      options: [
        {
          label: t('page.cancellation_survey.form.options.very_satisfied'),
          value: 5
        },
        {
          label: t('page.cancellation_survey.form.options.satisfied'),
          value: 4
        },
        {
          label: t('page.cancellation_survey.form.options.indifferent'),
          value: 3
        },
        {
          label: t('page.cancellation_survey.form.options.dissatisfied'),
          value: 2
        },
        {
          label: t('page.cancellation_survey.form.options.very_unsatisfied'),
          value: 1
        }
      ]
    },
    {
      label: `6- ${t('page.cancellation_survey.form.services_satisfaction.label')}`,
      resume: t('page.cancellation_survey.form.services_satisfaction.resume'),
      value: null,
      key: 'servicesSatisfaction',
      type: 'change',
      options: [
        {
          label: t('page.cancellation_survey.form.options.very_satisfied'),
          value: 5
        },
        {
          label: t('page.cancellation_survey.form.options.satisfied'),
          value: 4
        },
        {
          label: t('page.cancellation_survey.form.options.indifferent'),
          value: 3
        },
        {
          label: t('page.cancellation_survey.form.options.dissatisfied'),
          value: 2
        },
        {
          label: t('page.cancellation_survey.form.options.very_unsatisfied'),
          value: 1
        }
      ]
    },
    {
      label: `7- ${t('page.cancellation_survey.form.user_return.label')}`,
      resume: '',
      value: null,
      key: 'return',
      type: 'change',
      options: [
        {
          label: t('page.cancellation_survey.form.options.certainly'),
          value: 5
        },
        {
          label: t('page.cancellation_survey.form.options.problably'),
          value: 4
        },
        {
          label: t('page.cancellation_survey.form.options.not_sure'),
          value: 3
        },
        {
          label: t('page.cancellation_survey.form.options.problably_nod'),
          value: 2
        },
        {
          label: t('page.cancellation_survey.form.options.definitely_not'),
          value: 1
        }
      ]
    },
    {
      label: `8- ${t('page.cancellation_survey.form.opnion.label')}`,
      resume: '',
      value: '',
      key: 'options',
      type: 'text',
      options: [
        {
          label: t('page.cancellation_survey.form.options.very_satisfied'),
          value: 5
        },
        {
          label: t('page.cancellation_survey.form.options.satisfied'),
          value: 4
        },
        {
          label: t('page.cancellation_survey.form.options.indifferent'),
          value: 3
        },
        {
          label: t('page.cancellation_survey.form.options.dissatisfied'),
          value: 2
        },
        {
          label: t('page.cancellation_survey.form.options.very_unsatisfied'),
          value: 1
        }
      ]
    }
  ]
}