<template>
  <div>
    <div v-if="hasData">
      <div class="mt-8">
        <p
          v-html="$t('page.cancellation_survey.sentence.about_mkt_strategy-html', { timeToResult: usageTimeChart.timeToResult })"
        />
        <div v-if="isBeforeResultTime">
          <p
            v-html="$t('page.cancellation_survey.sentence.cancel_before_result', { userAvarage: usageTimeChart.userAvarage })"
          />
        </div>
        <div v-else>
          <p
            v-html="$t('page.cancellation_survey.sentence.cancel_after_result', { userAvarage: usageTimeChart.userAvarage })"
          />
        </div>
      </div>

      <div class="relative my-32 w-full md:w-4/5 mx-auto">
        <div
          class="-mt-24 border-l-2 absolute h-24 border-gray-400"
          :style="{ left: usageTimeChart.timeTuResultPercent + '%' }"
        >
          <div
            class="text-sm transform -rotate-90 -ml-3.5 mt-8 leading-4"
            v-html="$t('page.cancellation_survey.sentence.start_result_chart-html')"
          />
        </div>

        <div
          class="-mt-24 border-l-2 absolute h-24 border-gray-400"
          :style="{ left: usageTimeChart.userAvaragePercent + '%' }"
        >
          <div
            class="text-sm transform -rotate-90 -ml-6 mt-6 leading-4"
            v-html="$t('page.cancellation_survey.sentence.you_on_manycontent_chart-html')"
          />
        </div>
        
        <div class="progress border border-gray-200 h-2 shadow-md rounded-full">
          <div
            class="progress-bar rounded-full"
            :class="usageTimeChart.color"
            :style="{ width: usageTimeChart.userAvaragePercent + '%' }"
          />
        </div>
        <div class="absolute text-xs mt-1">
          0
        </div>
        <div
          class="absolute text-xs mt-1"
          :style="{ left: usageTimeChart.timeTuResultPercent + '%' }"
        >
          {{ usageTimeChart.timeToResult }} {{ $t('contant.days') }}
        </div>
        <div
          class="absolute text-xs mt-1"
          :style="{ left: usageTimeChart.userAvaragePercent + '%' }"
        >
          {{ usageTimeChart.userAvarage }} {{ $t('contant.days') }}
        </div>
      </div>
      <div>
        <h3
          class="text-center mb-8"
          v-html="$t('page.cancellation_survey.sentence.offer-tittle-html')"
        />

        <div
          v-html="$t('page.cancellation_survey.sentence.offer-html')"
        />
      </div>     
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs, unref } from 'vue'

export default defineComponent({

  props: {
    profilesUsageTime: {
      type: Array,
      default: () => ([])
    }
  },
  
  setup (props) {
    const { profilesUsageTime } = toRefs(props)

    const avarageUsageTime = computed(() => {
      const sum = unref(profilesUsageTime)
        .reduce((current: number, profile: any) => (current + profile.usageTimeDays), 0)

      return sum / unref(profilesUsageTime).length
    })

    const usageTimeChart = computed(() => {
      const timeToResult = 90
      const userAvarage = unref(avarageUsageTime)
      const max = Math.max(timeToResult, userAvarage) + ( (Math.max(timeToResult, userAvarage) / 5) )

      let timeTuResultPercent = (timeToResult / max) * 100
      let userAvaragePercent = (userAvarage / max) * 100
      const color = userAvarage <= timeToResult ? 'bg-red-500' : 'bg-green-400'

      // Evitar que os valores fiquem sobrepostos no gráfico quendo esses são muito próximos
      if ((Math.min(timeToResult, userAvarage) / Math.max(timeToResult, userAvarage)) * 100 > 80) {
        if (timeToResult < userAvarage) {
          timeTuResultPercent = timeTuResultPercent - timeTuResultPercent / 6
        } else {
          userAvaragePercent = userAvaragePercent - userAvaragePercent / 6
        }
      }

      return {
        timeToResult,
        userAvarage,
        timeTuResultPercent,
        userAvaragePercent,
        color
      }
    })

    const isBeforeResultTime = computed(() => {
      return unref(usageTimeChart).userAvarage <= unref(usageTimeChart).timeToResult
    })
    
    const hasData = computed(() => {
      return !!unref(profilesUsageTime).length
    })

    return {
      hasData,
      usageTimeChart,
      isBeforeResultTime
    }
  }
})
</script>
