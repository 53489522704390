import { auth, firestore } from '@/plugins/firebase'

export const listSubscriptions = async () => {
  const currentUser = auth.currentUser

  if (currentUser) {
    const subscriptionsQuery = await firestore
      .collection('subscriptions')
      .where('user', '==', currentUser.uid)
      .orderBy('created_at', 'desc')
      .get()
  
    if (!subscriptionsQuery.empty) {
      return subscriptionsQuery.docs.map(subscription => ({
        id: subscription.id,
        ...subscription.data()
      }))
    }
  }
  
  return []
}