
import { computed, defineComponent, onBeforeMount, reactive, ref, toRaw, unref } from 'vue'
import { useStore } from 'vuex'
import { differenceInCalendarDays } from 'date-fns'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'
import { firestore } from '@/plugins/firebase'
import { currentUser } from '@/composables/user/currentUser'
import { listCancelationRequest } from '@/composables/user/useCancelationRequest'
import { listSubscriptions } from '@/composables/user/useSubscriptions'
import { getQuestions } from '@/components/cancelation/SurveyQuestions'
import { useScrollTop } from '@/composables/useScrollTop'
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import ProfilesUsageTimeOffer from '@/components/cancelation/profilesUsageTimeOffer.vue'
import SurveyOptions from '@/components/cancelation/SurveyOptions.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    DashboardContent,
    ProfilesUsageTimeOffer,
    SurveyOptions,
  },

  setup () {
    const store = useStore()
    const router = useRouter()

    const { user } = currentUser()

    const isLoaded = ref(false)
    const step = ref(1)
    const subscriptions = ref<any>([])
    const cancelationsRequest = ref<any>([])
    const selectedProfiles = ref<string[]>([])
    const questions = getQuestions()
    const surveyQuestions = reactive(questions)

    const { t } = useI18n()

    onBeforeMount(async() => {
      subscriptions.value = await listSubscriptions()
      cancelationsRequest.value = await listCancelationRequest()
      isLoaded.value = true
    })

    const profiles = computed(() => toRaw(store.getters['profiles/all']))

    const activeProfiles = computed(() => {
      return unref(profiles)
        .map((profile: any) => {
          const profileSubscription = unref(subscriptions)
            .find((subscription: any) => subscription.profile === profile.id)
          const cancelationRequest = unref(cancelationsRequest)
            .find((cancelation: any) => cancelation.profile === profile.id)
          return {
            id: profile.id,
            name: profile.name,
            subscriptionId: profileSubscription ? profileSubscription.id : null,
            cancelationRequestId: cancelationRequest ? cancelationRequest.id : null,
            categoryName: profile.category? profile.category.name : null
          }
        })
        .filter((profile: any) => profile.subscriptionId && !profile.cancelationRequestId)
    })

    const isValid = computed(() => {
      const completedQuestions = unref(surveyQuestions)
        .filter((question: { type: string, value: number | string | null }) => {
          if (question.type === 'change') {
            return question.value && question.value > 0
          } else if (question.type === 'text') {
            return question.value !== ''
          }
        })

      return unref(surveyQuestions).length === completedQuestions.length && unref(selectedProfiles).length
    })

    const userName = computed(() => {
      return unref(user).name?.split(' ')[0]
    })

    const profilesUsageTime = computed(() => {
      return unref(profiles)
        .filter((profile: any) => {
          return unref(selectedProfiles).includes(profile.id)
        })
        .map((profile: any) => {
          const createdAt = new Date(profile.createdAt.seconds * 1000)
          return {
            name: profile.name,
            createdAt,
            usageTimeDays: differenceInCalendarDays(new Date(), createdAt)
          }
        })
    })

    const insatisfactions = computed(() => {
      return unref(surveyQuestions)
        .filter((question: { type: string, resume: string, value: number | string | null }) => {
          return question.type === 'change'
            && question.resume !== ''
            && question.value
            && question.value <= 3
        })
        .map((question: { resume: string }) => question.resume.toLowerCase())
    })

    const satisfactions = computed(() => {
      return unref(surveyQuestions)
        .filter((question: { type: string, resume: string, value: number | string | null }) => {
          return question.type === 'change'
            && question.resume !== ''
            && question.value
            && question.value > 3
        })
        .map((question: { resume: string }) => question.resume.toLowerCase())
    })

    const selectAll = (e: Event & { target: HTMLInputElement }) => {
      const element = e.target
      if (element.checked) {
        selectedProfiles.value = unref(activeProfiles).map((profile: { id: string }) => profile.id)
      } else {
        selectedProfiles.value = []
      }
    }

    const submitQuestions = () => {
      step.value = 2
      useScrollTop('.content')
    }

    const requestCancelation = async (acceptOffer: boolean = false) => {
      try {
        const survey = unref(surveyQuestions)
          .reduce((current: any, profile: any) => {
            return {
              ...current,
              [profile.key]: profile.value
            }
          }, {})

        const solicitations = unref(profiles)
          .filter((profile: any) => {
            return unref(selectedProfiles).includes(profile.id)
          })
          .map((profile: any) => {
            return {
              survey,
              signatureDate: new Date(profile.plan.signatureDate.seconds * 1000),
              profile: profile.id,
              profileName: profile.name,
              subscriptionId: unref(subscriptions).find((subscription: any) => subscription.profile === profile.id).id,
              user: unref(user).id,
              email: unref(user).email,
              requestDate: new Date(),
              status: 'Open',
              acceptOffer,
              offer: acceptOffer ? 'Consultoria' : null
            }
          })

        await Promise.all(
          solicitations.map(async (solicitation: any) => {
            return firestore.collection('cancelRequests').add(solicitation)
          })
        )

        Swal.fire({
          title: t('state.cancellation_survey.title'),
          text: t('state.cancellation_survey.message'),
          confirmButtonText: t('state.cancellation_survey.action_confirm'),
        }).then(result => {
          if (result.value) {
            router.push({ name: 'home' })
          }
        })
      } catch (error) {
        console.log(error)
        Swal.fire({
          title: t('state.cancellation_survey.error_title'),
          text: t('state.cancellation_survey.error_message'),
          confirmButtonText: t('state.cancellation_survey.error_action_confirm'),
        })
      }
    }

    return {
      activeProfiles,
      insatisfactions,
      isLoaded,
      isValid,
      profilesUsageTime,
      requestCancelation,
      satisfactions,
      selectAll,
      selectedProfiles,
      step,
      submitQuestions,
      surveyQuestions,
      user,
      userName,
    }
  }
})
