
import { computed, defineComponent, toRefs, unref } from 'vue'

export default defineComponent({

  props: {
    profilesUsageTime: {
      type: Array,
      default: () => ([])
    }
  },
  
  setup (props) {
    const { profilesUsageTime } = toRefs(props)

    const avarageUsageTime = computed(() => {
      const sum = unref(profilesUsageTime)
        .reduce((current: number, profile: any) => (current + profile.usageTimeDays), 0)

      return sum / unref(profilesUsageTime).length
    })

    const usageTimeChart = computed(() => {
      const timeToResult = 90
      const userAvarage = unref(avarageUsageTime)
      const max = Math.max(timeToResult, userAvarage) + ( (Math.max(timeToResult, userAvarage) / 5) )

      let timeTuResultPercent = (timeToResult / max) * 100
      let userAvaragePercent = (userAvarage / max) * 100
      const color = userAvarage <= timeToResult ? 'bg-red-500' : 'bg-green-400'

      // Evitar que os valores fiquem sobrepostos no gráfico quendo esses são muito próximos
      if ((Math.min(timeToResult, userAvarage) / Math.max(timeToResult, userAvarage)) * 100 > 80) {
        if (timeToResult < userAvarage) {
          timeTuResultPercent = timeTuResultPercent - timeTuResultPercent / 6
        } else {
          userAvaragePercent = userAvaragePercent - userAvaragePercent / 6
        }
      }

      return {
        timeToResult,
        userAvarage,
        timeTuResultPercent,
        userAvaragePercent,
        color
      }
    })

    const isBeforeResultTime = computed(() => {
      return unref(usageTimeChart).userAvarage <= unref(usageTimeChart).timeToResult
    })
    
    const hasData = computed(() => {
      return !!unref(profilesUsageTime).length
    })

    return {
      hasData,
      usageTimeChart,
      isBeforeResultTime
    }
  }
})
