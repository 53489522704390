<template>
  <ul class="ml-4 my-4">
    <li
      class="flex items-center space-x-2"
      v-for="option in options"
      :key="option.value"
    >
      <input class="cursor-pointer" type="radio" :id="`option-${surveyId}-${option.value}`" :value="option.value" v-model="selectedOption" />
      <label class="cursor-pointer" :for="`option-${surveyId}-${option.value}`">{{ option.label }}</label>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, ref, unref, watch } from 'vue'

export default defineComponent({
  props: {
    surveyId: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => ([])
    }
  },

  emits : [
    'update:modelValue'
  ],
  
  setup (props, { emit }) {
    const selectedOption = ref<number | null>(null)

    watch(selectedOption, () => {
      emit('update:modelValue', unref(selectedOption))
    })

    return {
      selectedOption
    }
  }
})
</script>
