import { auth, firestore } from '@/plugins/firebase'

export const listCancelationRequest = async () => {
  const currentUser = auth.currentUser

  if (currentUser) {
    const query = await firestore
      .collection('cancelRequests')
      .where('user', '==', currentUser.uid)
      .where('status', '==', 'Open')
      .get()
  
    if (!query.empty) {
      return query.docs.map(snapshot => ({
        id: snapshot.id,
        ...snapshot.data()
      }))
    }
  }
  
  return []
}